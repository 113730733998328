<template>
  <validation-observer
    ref="observer"
    autocomplete="off"
    tag="v-form"
  >
    <v-dialog
      v-model="dialog"
      :fullscreen="openFullscreen"
      :hide-overlay="openFullscreen"
      content-class="confirmation__payment-schedule-dialog"
      max-width="600"
      persistent
    >
      <v-card>
        <v-toolbar
          class="confirmation__payment-schedule-dialog__toolbar"
          color="transparent"
          flat
        >
          <v-toolbar-title>
            <translate>Choose your first withdrawal</translate>
          </v-toolbar-title>
          <v-spacer/>
          <v-btn
            v-if="openFullscreen"
            id="cancel-dialog"
            icon
            @click="
              onDialogClose();
              $eventLogger.clickEvent($event);
            "
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-0 px-6">
          <v-row class="confirmation__payment-schedule">
            <v-col
              class="confirmation__payment-schedule__withdrawal-amount--wrapper relative pa-0 pt-4"
              cols="12"
            >
              <p>
                <translate
                  :translate-params="{ loanLimit: $filters.currency(offer.loanLimit, { locale }) }"
                >
                  After you confirm your loan agreement, you will have a credit line of
                  %{ loanLimit }, which you can withdraw at any time within your credit line.
                </translate>
              </p>
              <cw-text-field
                id="withdrawalAmount"
                ref="withdrawalAmount"
                v-model="amount"
                :rules="`required|between:${withdrawal.min / 100},${withdrawal.max / 100}`"
                :class="{ 'busy': $wait.waiting('withdrawalAmount') }"
                :hint="withdrawalAmountHint"
                :label="withdrawalAmountLabel"
                class="mb-6"
                lang="en-150"
                type="number"
                currency-input
                prevent-set-data
              />
              <label class="confirmation__payment-schedule__progress-label">
                <translate>Credit limit</translate> {{ offer.loanLimit | currency({ locale }) }}
              </label>
              <v-progress-linear
                :value="withdrawalDiagram.percentage"
                class="mb-2 mt-1"
                color="primary"
                height="15"
              />
              <v-row
                class="mx-0 mt-0 mb-0 pa-0 flex"
                align="center"
                justify="space-between"
              >
                <p class="mb-0 confirmation__payment-schedule__title pb-1">
                  <span
                    class="primary confirmation__payment-schedule__indicator"
                  />
                  <translate>
                    Can be withdrawn later
                  </translate>
                </p>
                <p
                  class="
                    confirmation__payment-schedule__total--small
                    confirmation__payment-schedule__remaining
                  "
                >
                  {{ withdrawalDiagram.remainder | currency({ locale }) }}
                </p>
              </v-row>
              <v-row
                class="ma-0 pa-0"
                align="center"
                justify="space-between"
              >
                <p class="mb-0 confirmation__payment-schedule__title pb-1">
                  <span
                    class="primary confirmation__payment-schedule__title__background
                    confirmation__payment-schedule__indicator"
                  />
                  <translate>
                    First withdrawal
                  </translate>
                </p>
                <p
                  class="
                    confirmation__payment-schedule__total--small
                    confirmation__payment-schedule__remaining"
                >
                  {{ withdrawalDiagram.value | currency({ locale }) }}
                </p>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-if="!openFullscreen"
            id="cancel-dialog"
            text
            @click="
              onDialogClose();
              $eventLogger.clickEvent($event);
            "
          >
            <translate>
              Cancel
            </translate>
          </v-btn>
          <v-btn
            id="change-first-withdrawal"
            :disabled="amount < withdrawal.min || amount > withdrawal.max"
            color="primary"
            text
            @click="
              updateWithdrawalAmount();
              $eventLogger.clickEvent($event);
            "
          >
            <translate>
              Confirm changes
            </translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'CwConfirmationPaymentSchedule',

  components: {
    'cw-text-field': () => import('@shared/components/inputs/CwTextField'),
    'validation-observer': ValidationObserver,
  },

  data: () => ({
    amount: null,
    dialog: false,
    withdrawalDiagram: {
      percentage: null,
      remainder: null,
      value: null,
    },
  }),

  computed: {
    ...mapGetters({
      locale: 'application/locale',
    }),

    ...mapState({
      initialData: state => state.application.initialData,
      offer: state => state.application.selectedOffer,
      applicationSummary: state => state.application.applicationSummary,
      withdrawalSummary: state => state.application.withdrawalSummary,
    }),

    openFullscreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    withdrawal() {
      return {
        min: 30000,
        max: this.offer.loanLimit,
      };
    },

    /**
     * Gettext translations
     */
    withdrawalAmountHint: vm => vm.$gettext('Enter the withdrawal amount'),
    withdrawalAmountLabel: vm => vm.$gettext('First withdrawal'),
  },

  watch: {
    amount: {
      handler() {
        this.onAmountChange();
      },
      immediate: true,
    },
  },

  mounted() {
    this.$bus.$on('PAYMENT_SCHEDULE_DIALOG__OPEN', () => this.onDialogOpen());
  },

  methods: {
    ...mapActions({
      setWithdrawalSummary: 'application/setWithdrawalSummary',
      setData: 'application/setData',
      removeApiError: 'application/removeApiError',
    }),

    async onDialogClose() {
      this.dialog = false;
    },

    onDialogOpen() {
      this.dialog = true;
    },

    onAmountChange() {
      const { max, min } = this.withdrawal;

      // prevent withdrawal amount from being larger than the max amount in offer
      if (this.amount > parseInt(max, 10)) {
        this.amount = parseInt(max, 10);
      }

      if (
        this.amount
          && this.amount <= parseInt(max, 10)
          && this.amount >= parseInt(min, 10)
      ) {
        this.withdrawalDiagram = {
          percentage: 100 - (
            (this.amount / parseInt(this.offer.loanLimit, 10)) * 100
          ),
          remainder: this.offer.loanLimit - this.amount,
          value: this.amount,
        };
      }
    },

    async updateWithdrawalAmount() {
      let valid;
      this.removeApiError('withdrawalAmount');

      await this.$nextTick();
      valid = await this.$refs.observer.validate();

      if (!valid) return;

      this.setData({ withdrawalAmount: this.amount });

      valid = await this.$refs.observer.validate();

      if (!valid) return;

      this.$emit('update-withdrawal-amount', this.amount);
      this.updateWithdrawalSummary();
      this.onDialogClose();
    },

    /**
     * Update withdrawal summary
     *
     * @param {String} amount - Withdrawal amount
     */
    updateWithdrawalSummary() {
      const updatedSummary = cloneDeep(this.withdrawalSummary).map((item) => {
        if (item.id === 'withdrawalAmount') {
          item.value = this.$filters.currency(this.amount, { locale: this.locale }); // eslint-disable-line
        }

        return item;
      });

      this.setWithdrawalSummary(updatedSummary);
    },
  },
};
</script>

<style lang="scss">
.confirmation {
  &__payment-schedule {

    &__progress-label {
      font-size: 12px;
      color: rgba(0,0,0,0.54);
    }

    &__title {
      font-weight: bold;

      &__background {
        opacity: 0.3;
      }
    }

    &__indicator {
      border-radius: 50%;
      display: inline-block;
      height: 1rem;
      margin-right: 0.5rem;
      overflow: hidden;
      position: relative;
      top: 2px;
      width: 1rem;

      &--waiting {
        opacity: 0.3;
      }

      &--used {
        background-color: transparent;
        border: 1px solid currentColor;
      }
    }

    &__total {
      font-size: 2.5rem;
      line-height: 1.2;

      &--small {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .confirmation__payment-schedule__withdrawal-amount--wrapper,
  .confirmation__payment-schedule__payment-schedule--wrapper {
    min-height: 80px;
  }
}
</style>
